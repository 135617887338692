// bower:scss
// endbower

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin transform($transforms) {
       -moz-transform: $transforms;
         -o-transform: $transforms;
        -ms-transform: $transforms;
    -webkit-transform: $transforms;
          transform: $transforms;
}

@mixin font-lato--bold {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

@mixin font-lato--thin {
    font-family: 'Lato', sans-serif;
    font-weight: 100;
}

@mixin font-lato--light {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

@mixin font-lato--normal {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

@mixin constrict {
    max-width: 1920px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes animatedBackground {
    from { background-position: 0 0; }
    to { background-position: 1178px 0; }
}

@keyframes animatedBackgroundOpposite {
    from { background-position: 0 0; }
    to { background-position: -1178px 0; }
}

@keyframes rotateSpinner {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}

$colorA: #1F2427;
$colorB: #F25028;

body {
  @include font-lato--normal;
  background-color: #FFFFFF;
  padding: 0;
  margin: 0;
}

body.noscroll{
    overflow: hidden;
    height: 100% !important;
}

.spinner {
    height: 20px;
    width: 20px;
    animation: rotateSpinner 0.33s infinite linear;
    border: 4px solid $colorB;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    border-radius: 50%;
    display: inline-block;
    transform: translateZ(0);
    vertical-align: middle;
}

.header {
    line-height: 78px;
    background-color: darken($colorA, 1);
    .nav {
        float: right;
        line-height: 78px;
        margin: 0;
        padding: 0;
        list-style-type: none; 
        color: #FFF;
        font-size: 14px;
        li {
            display: inline-block;
            .button {
                padding: 0;
                font-size: 14px;
                line-height: 36px;
                width: 130px;
                border: 1px solid rgba(255,255,255,0.15);
                background-color: transparent;
                &:hover {
                    background-color: $colorB;
                    border: 1px solid transparent;
                }
            }
        }
    }
    .home-link {
        display: inline-block;
        img {
            position: relative;
            top: 4px;
        }
        &:hover {
            img {
                opacity: 0.9;
            }
        }
        &:active {
            img {
                opacity: 0.75;
            }
        }
    }
}
.hero {
    background-image: url(/images/mosaic.jpg);
    height: 881px;
    overflow: hidden;
    background-color: darken(#252A2C, 10);
    &.is-animate {
        animation: animatedBackground 70s linear infinite;
    }
    .loading {
        text-align: center;
        width: 100%;
        position: absolute;
        height: 700px;
        line-height: 700px;
    }
    .overlay {
        background-color: lighten(rgba(31, 36, 39, 0.48), 13.2);
        min-height: 740px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .whiteout {
        background-color: #FFF;
        height: #{881-705}px;
        margin-top: -110px;
    }
    .container {
        height: 815px;
        position: relative;
        top: 50px;
        background-repeat: no-repeat;
        width: 2410px;
        box-sizing: border-box;
        background-image: url(/images/hero.png);
        margin-left: -10000px;
        background-size: 1300px auto;
        .copy {
            box-sizing: border-box;
            margin: 0 auto;
            .padding {
                box-sizing: border-box;
                padding-left: 53%;
                padding-top: 130px;
                padding-right: 40px;
            }
            .p {
                max-width: 600px;
            }
            .button {
                margin-top: 40px;
                width: 100%;
                max-width: 260px;
            }
        }
        &.is-loaded {
            //@include transition(margin 0.5s 0s ease);
            display: block;
        }
    }
}

.h2 {
    color: #fff;
    @include font-lato--thin;
    margin: 0;
    font-size: 48px;
    line-height: 50px;
    padding-bottom: 30px;
    .tm {
        font-size: 18px;
        line-height: 50px;
        vertical-align: top;
        display: inline-block;
        margin-top: -5px;
    }
}

.p {
    color: #fff;
    @include font-lato--light;
    line-height: 32px;
    padding: 10px 0;
    font-size: 15px;
}

.button {
    background-color: $colorB;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    @include font-lato--normal;
    font-size: 16px;
    padding: 21px 30px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    @include transition(background-color 0.1s 0s linear, border 0.1s 0s linear);
    user-select: none;
    text-decoration: none;
    &:hover {
        background-color: lighten($colorB, 4);
        text-shadow: 0 2px 2px darken($colorB, 13);
    }
    &:active {
        background-color: lighten($colorB, 7);
        @include transform(translateY(1px));
    }
}

.brands {
    text-align: center;
    line-height: 190px;
    padding-bottom: 30px;
    border-bottom: 1px solid #CACACA;
    margin: 0 40px;
    img {
        vertical-align: middle;
        max-width: 200px;
        width: 12%;
        height: auto;
        margin: 0 20px;
        box-sizing: border-box;
        opacity: .5;
    }
}

.numbers {
    padding: 80px 40px 80px 100px;
    @include clearfix;
    background-color: #fff;
    .u-constrict {
        max-width: 1600px;
    }
    .p {
        color: #444;
        max-width: 700px
    }
    h2 {
        padding-top: 80px;
        color: #444;
        border: none;
        font-size: 46px;
    }
    img {
        width: 45%;
        height: auto;
        max-width: 739px;
        float: right;
        margin-left: 100px;
    }
}

.hiring-shield {
    background-color: rgba(30,30,30,.8);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;    
    left: 0;    
    right: 0;    
    bottom: 0;   
    z-index: 998; 
    display: none;
}

.campaigns {
    background-image: url(/images/mosaic.jpg);
    animation: animatedBackgroundOpposite 70s linear infinite;
    background-color: #000;
    .u-constrict {
        max-width: 1600px;
    }
    .h2 {
        font-size: 46px;
        padding-top: 180px;
        line-height: 70px;
    }
    .p {
        padding-right: 40px;
    }
    img {
        border-radius: 4px 4px 0 0;
        margin-bottom: -3px;
        height: auto;
        width: 60%;
        max-width: 860px;
        float: left;
        margin-right: 100px;
    }
    .u-constrict {
        max-width: 1600px;
    }
    .overlay {
        padding-top: 85px;
        background-color: rgba(0, 45, 109, 0.18);
        @include clearfix;
    }
}

.hiring {
    display: none;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 50px 0;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(-15px);
    transition: opacity .4s, transform .4s;
    .close {
        float: right;
        margin-right: -38px;
        margin-top: -20px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 4px;
        font-size: 14px;
        color: #aaa;
        &:hover {
            color: $colorB;
            cursor: pointer;
        }
        &:active {
            margin-top: -19px;
        }
    }
    &.is-shown {
        opacity: 1;
         transform: translateY(0px);
    }
    
    &__container {
        background-color: #FFF;
        max-width: 800px;
        margin: 0 auto;
        padding: 30px 50px 30px 50px;
        border-radius: 4px;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,.1);
        .interested {
            border-top: 1px solid #ececec;
            padding-top: 15px;
            padding-bottom: 10px;
            .button {
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 15px;
                padding-right: 15px;
                font-size: 14px;
                margin-left: 20px;
            }
        }
        a {
            color: $colorB;
        }
        p, h4, h3, h2, ul {
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
        }
        h3 {
            @include font-lato--light;
            font-size: 38px;
            line-height: 50px;
            margin: 5px 0px 20px 0px;
            color: #333;
            border-bottom: 1px solid #ececec;
            padding-bottom: 30px;
            padding-left: 10px;
            padding-right: 10px;
            img {
                height: 32px;
                width: 32px;
                vertical-align: middle;
                border-radius: 50%;
                border: 3px solid #fff;
                box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
                margin-top: -5px;
            }
        }
        h4 {
            @include font-lato--light;
            font-size: 20px;
            line-height: 50px;
            margin: 10px 10px 0px 10px;
            color: #333;
        }
        p {
            @include font-lato--light;
            line-height: 28px;
            padding: 10px 0;
            font-size: 15px;
            color: #202020;
        }
        ul {
            margin-left: 25px;
            padding-bottom: 30px;
        }
        li {
            @include font-lato--light;
            line-height: 28px;
            font-size: 15px;
            color: #202020;
        }
    }
}

.discover {
    background-color: #EAEAEA;
    padding-left: 30px;
    padding-top: 80px;
    padding-bottom: 100px;
    @include clearfix;
    .u-constrict {
        max-width: 1540px;
    }
    .screen {
        max-width: 891px;
        height: auto;
        width: 100%;
        float: left;
        margin-right: 70px;
    }
    .p {
        color: #444;
        max-width: 700px
    }
    h2 {
        color: #444;
        border: none;
        font-size: 46px;
    }
    .stats {
        border-left: 1px solid #EF512C;
        padding-left: 20px;
        margin-top: 75px;
        float: left;
        margin-right: -60px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            height: 100px;
            width: 116px;
            margin-left: -136px;
            margin-top: 16px;
            border-bottom: 1px solid #EF512C;
        }
        h4 {
            margin-bottom: 0;
            margin-top: 0;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 13px;
            color: #757575;
            padding-bottom: 4px;
        }
        td {
            font-size: 18px;
            width: 250px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.demo {
    background-image: url(/images/mosaic.jpg);
    animation: animatedBackgroundOpposite 70s linear infinite;
    background-color: #000;
    .u-constrict {
        max-width: 1100px;
    }
    .overlay {
        padding: 60px 0;
        background-color: rgba(0, 45, 109, 0.18);
        @include clearfix;
    }
    .p {
        font-size: 16px;
    }
    .button {
        float: left;
        margin-top: 0px;
        margin-right: 40px;
        width: 200px;
    }
}
.footer {
    background-color: #010509;
    padding-top: 100px;
    padding-bottom: 10px;
    color: #777;
    font-weight: 300;
    font-size: 13px;
    text-align: right;
}

.form {
    background-color: #fff;
    text-align: center;
    padding: 25px 0;
    max-width: 520px;
    margin: 0 auto;
    border-radius: 4px;
    @include clearfix;
    padding-bottom: 0;
    overflow: hidden;
    margin-top: 70px;
    margin-bottom: 70px;
    display: none;
    .success {
        display: none;
    }
    &.is-success {
        .success {
            display: block;
            padding-bottom: 25px;
            .p {
                color: #444;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .hideOnSuccess {
            display: none;
        }
    }
    .button {
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-radius: 0;
        padding-top: 27px;
        padding-bottom: 27px;
        margin-top: 30px;
        outline: none;
        .spinner {
            display: none;
        }
    }
    .validate {
        background-color: #FEF4F2;
        border: 1px solid #FEECE8;
        color: #D2310A;
        padding: 20px 10px;
        margin-bottom: -30px;
        display: none;
    }
    input {
        @include font-lato--normal;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #eaeaea;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        font-weight: 300;
        padding-left: 30px;
        padding-right: 30px;
        outline: none;
        &:focus {
            border-color: #F25028;
        }
    }
    .h2 {
        margin-bottom: 0;
        padding-bottom: 0;
        color: #555;
        padding-bottom: 25px;
        border-bottom: 1px solid #ddd;
        font-size: 40px;
    }
    &.is-loading {
        .button {
            background-color: #ddd;
            cursor: default;
            color: #aaa;
            .spinner {
                display: inline-block;
                margin-top: -4px;
                margin-left: -45px;
                position: absolute;
            }
            &:hover {
                text-shadow: none;
            }
        }
    }
}

@media (max-width: 1520px) {
    .discover {
        text-align: center;
        .p {
            margin: 0 auto;
            padding-bottom: 40px;
        }
        .screen {
            float: none;
            margin: 0 auto;
            margin-bottom: 30px;
        }
        .stats {
            width: auto;
            float: none;
            margin: 0 auto;
            border-left: none;
            border-top: 1px solid #EF512C;
            padding-left: 0;
            width: 430px;
            padding-left: 20px;
            td {
                text-align: left;
                width: 250px;
                &:last-child {
                    width: 180px;
                }
            }
            &:before {
                content: '';
                display: block;
                position: absolute;
                height: 377px;
                width: 116px;
                margin-left: 250px;
                margin-top: -377px;
                border-right: 1px solid #EF512C;
                border-bottom: none;
            }
        }
    }
}

@media (max-width: 1400px) {
    .campaigns {
        .p {
            padding-right: 30px;
        }
        img {
            margin-right: 50px;
        }
    }
}

@media (max-width: 1215px) {
    .numbers {
        padding-left: 50px;
        padding-left: 25px;
        padding-right: 25px;
        img {
            margin-left: 40px;
        }
        .h2 {
            padding-top: 0;
        }
    }
    .campaigns {
        .h2 {
            padding-top: 0;
        }
        img {
            width: 50%;
        }
    }
}

@media (max-width: 1100px) {
    .campaigns {
        text-align: center;
        .overlay {
            padding-top: 70px;
            padding-bottom: 40px;
        }
        .h2 {
            padding-bottom: 0;
        }
        img {
            float: none;
            border-bottom: 1px solid #aaa;
            margin-bottom: 20px;
            margin-right: 0;
            width: 100%;
            max-width: 550px;
        }
        .p {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

@media (max-width: 1050px) {
    .hero {
        height: auto;
        .whiteout {
            display: none;
        }
        .overlay {
            padding-bottom: 0px;
        }
        .container {
            margin: 0 !important;
            width: auto !important;
            background-size: 500px auto;
            background-position: top center;
            .copy {
                opacity: 0;
                margin: 0;
                text-align: center;
                .padding {
                    padding: 0;
                    padding-top: 330px;
                }
                p {
                    margin: 0 auto;
                }
            }
        }
    }
    body.is-loaded {
        .hero {
            .container {
                .copy {
                    opacity: 1;
                }
            }
        }
    }
    .brands {
        padding: 10px 0;
        margin: 0;
        line-height: auto;
        line-height: 16px;
        img {
            display: inline-block;
            width: 40%;
            margin: 0;
            max-width: 150px;
            height: auto;
        }
    }
    .demo {
        padding: 0;
        text-align: center;
        .overlay {
            padding-bottom: 20px;
            padding-top: 35px;
        }
        .button {
            float: none;
            margin: 0;
        }
        .p {
            padding-top: 20px;
            max-width: 600px;
            margin: 0 auto;
        }
    }
}

@media (max-width: 987px) {
    .numbers {
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0;
        img {
            margin: 0;
            float: none;
            width: 100%;
            max-width: 600px;
            border-bottom: 1px solid #eaeaea;
        }
        .h2 {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .p {
            margin: 0 auto;
        }
    }
    .discover {
        padding: 50px 30px;
        .stats {
            &:before {
                display: none;
            }
        }
    }
}

@media (max-width: 950px) {
    .hiring {
        &__container {
            margin-right: 30px;
            margin-left: 30px;
        }
    }
}

@media (max-width: 700px) {
    .hiring {
        &__container {
            padding-left: 30px;
            padding-right: 30px;
            .close {
                margin-right: -20px;
            }
        }
        h3 {
            line-height: 40px;
            font-size: 30px;
            span {
                display: block;
                padding-bottom: 10px;
            }
        }
        h4 {
            line-height: 30px;
            font-size: 24px;
            padding-left:  0;
            margin-left: 0;
        }
        ul {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

@media (max-width: 625px) {
    .header {
        text-align: center;
        .home-link {
            float: none;
        }
    }
    .nav {
        .button {
            display: none;
        }
    }
    .hero {
        min-height: 0px;
        max-width: none;
        height: auto !important;
        .whiteout {
            display: none;
        }
        .overlay {
            padding-bottom: 50px;
            height: auto !important;
        }
        .container {
            background-size: 110% auto;
            height: auto !important;
            padding-bottom: 60px;
            .copy {
                padding-bottom: 0px;
                padding-left: 20px;
                padding-right: 20px;
                .button {
                    margin-top: 20px;
                    margin-left: auto;
                    margin-right: auto;
                    width: auto;
                    display: block;
                }
                .padding {
                    padding-top: 80%;
                }
                h2 {
                    font-size: 32px;
                    padding-bottom: 15px;
                    line-height: 40px;
                    .tm {
                        font-weight: 300;
                        font-size: 14px;
                        margin-top: -16px;
                        opacity: 0.7;
                    }
                }
                p {
                    line-height: 26px;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }
    .numbers {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 30px;
        .h2 {
            font-size: 32px;
            padding-bottom: 15px;
            padding-top: 25px;
            line-height: 40px;
        }
        .p {
            padding-left: 15px;
            padding-right: 15px;
            line-height: 26px;
            text-align: center;
            font-size: 14px;
        }
    }

    .campaigns {
        .overlay {
            padding-top: 50px;
            padding-bottom: 20px;
        }
        .h2 {
            font-size: 32px;
            padding-bottom: 15px;
            padding-top: 25px;
            line-height: 40px;
        }
        .p {
            padding-left: 15px;
            padding-right: 15px;
            line-height: 26px;
            text-align: center;
            font-size: 14px;
        }
    }
    .discover {
        padding: 30px 0;
        .h2 {
            font-size: 32px;
            padding-bottom: 15px;
            padding-top: 25px;
            line-height: 40px;
        }
        .screen {
            margin-bottom: 15px;
        }
        .p {
            padding-left: 15px;
            padding-right: 15px;
            line-height: 26px;
            text-align: center;
            font-size: 14px;
        }
        .stats {
            width: auto;
            padding-top: 10px;
            table {
                display: block;
                width: auto;
            }
            tr {
                display: block;
                width: auto;
            }
            tbody {
                display: block;
                width: auo;
            }
            td {
                display: block;
                padding-top: 10px;
                padding-bottom: 10px;
                width: auto !important;
                text-align: left;
                border-bottom: 1px solid #dadada;
                h4 {
                    font-size: 11px;
                }
                font-size: 15px;
            }
        }
    }

    .demo {
        .p {
            font-size: 14px;
            line-height: 24px;
        }
    }

    .form {
        margin-top: 25px;
        margin-bottom: 35px;
        padding-top: 15px;
        input {
            font-size: 17px;
        }
        .h2 {
            font-size: 24px;
            padding-bottom: 15px;
        }
    }
}

.u-nowrap {
    white-space: nowrap;
}
.u-fr { float: right; }
.u-fl { float: left; }
.u-constrict { @include constrict }
