@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1178px 0;
  }
}

@keyframes animatedBackgroundOpposite {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1178px 0;
  }
}

@keyframes rotateSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  background-color: #FFFFFF;
  padding: 0;
  margin: 0;
}

body.noscroll {
  overflow: hidden;
  height: 100% !important;
}

.spinner {
  height: 20px;
  width: 20px;
  animation: rotateSpinner 0.33s infinite linear;
  border: 4px solid #F25028;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-radius: 50%;
  display: inline-block;
  transform: translateZ(0);
  vertical-align: middle;
}

.header {
  line-height: 78px;
  background-color: #1d2124;
}

.header .nav {
  float: right;
  line-height: 78px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #FFF;
  font-size: 14px;
}

.header .nav li {
  display: inline-block;
}

.header .nav li .button {
  padding: 0;
  font-size: 14px;
  line-height: 36px;
  width: 130px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background-color: transparent;
}

.header .nav li .button:hover {
  background-color: #F25028;
  border: 1px solid transparent;
}

.header .home-link {
  display: inline-block;
}

.header .home-link img {
  position: relative;
  top: 4px;
}

.header .home-link:hover img {
  opacity: 0.9;
}

.header .home-link:active img {
  opacity: 0.75;
}

.hero {
  background-image: url(/images/mosaic.jpg);
  height: 881px;
  overflow: hidden;
  background-color: #0e1010;
}

.hero.is-animate {
  animation: animatedBackground 70s linear infinite;
}

.hero .loading {
  text-align: center;
  width: 100%;
  position: absolute;
  height: 700px;
  line-height: 700px;
}

.hero .overlay {
  background-color: rgba(61, 71, 77, 0.48);
  min-height: 740px;
  margin: 0 auto;
  box-sizing: border-box;
}

.hero .whiteout {
  background-color: #FFF;
  height: 176px;
  margin-top: -110px;
}

.hero .container {
  height: 815px;
  position: relative;
  top: 50px;
  background-repeat: no-repeat;
  width: 2410px;
  box-sizing: border-box;
  background-image: url(/images/hero.png);
  margin-left: -10000px;
  background-size: 1300px auto;
}

.hero .container .copy {
  box-sizing: border-box;
  margin: 0 auto;
}

.hero .container .copy .padding {
  box-sizing: border-box;
  padding-left: 53%;
  padding-top: 130px;
  padding-right: 40px;
}

.hero .container .copy .p {
  max-width: 600px;
}

.hero .container .copy .button {
  margin-top: 40px;
  width: 100%;
  max-width: 260px;
}

.hero .container.is-loaded {
  display: block;
}

.h2 {
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  margin: 0;
  font-size: 48px;
  line-height: 50px;
  padding-bottom: 30px;
}

.h2 .tm {
  font-size: 18px;
  line-height: 50px;
  vertical-align: top;
  display: inline-block;
  margin-top: -5px;
}

.p {
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  line-height: 32px;
  padding: 10px 0;
  font-size: 15px;
}

.button {
  background-color: #F25028;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding: 21px 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  -moz-transition: background-color 0.1s 0s linear, border 0.1s 0s linear;
  -o-transition: background-color 0.1s 0s linear, border 0.1s 0s linear;
  -webkit-transition: background-color 0.1s 0s linear, border 0.1s 0s linear;
  transition: background-color 0.1s 0s linear, border 0.1s 0s linear;
  user-select: none;
  text-decoration: none;
}

.button:hover {
  background-color: #f3603b;
  text-shadow: 0 2px 2px #cb320c;
}

.button:active {
  background-color: #f46b4a;
  -moz-transform: translateY(1px);
  -o-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.brands {
  text-align: center;
  line-height: 190px;
  padding-bottom: 30px;
  border-bottom: 1px solid #CACACA;
  margin: 0 40px;
}

.brands img {
  vertical-align: middle;
  max-width: 200px;
  width: 12%;
  height: auto;
  margin: 0 20px;
  box-sizing: border-box;
  opacity: .5;
}

.numbers {
  padding: 80px 40px 80px 100px;
  background-color: #fff;
}

.numbers:after {
  content: "";
  display: table;
  clear: both;
}

.numbers .u-constrict {
  max-width: 1600px;
}

.numbers .p {
  color: #444;
  max-width: 700px;
}

.numbers h2 {
  padding-top: 80px;
  color: #444;
  border: none;
  font-size: 46px;
}

.numbers img {
  width: 45%;
  height: auto;
  max-width: 739px;
  float: right;
  margin-left: 100px;
}

.hiring-shield {
  background-color: rgba(30, 30, 30, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  display: none;
}

.campaigns {
  background-image: url(/images/mosaic.jpg);
  animation: animatedBackgroundOpposite 70s linear infinite;
  background-color: #000;
}

.campaigns .u-constrict {
  max-width: 1600px;
}

.campaigns .h2 {
  font-size: 46px;
  padding-top: 180px;
  line-height: 70px;
}

.campaigns .p {
  padding-right: 40px;
}

.campaigns img {
  border-radius: 4px 4px 0 0;
  margin-bottom: -3px;
  height: auto;
  width: 60%;
  max-width: 860px;
  float: left;
  margin-right: 100px;
}

.campaigns .u-constrict {
  max-width: 1600px;
}

.campaigns .overlay {
  padding-top: 85px;
  background-color: rgba(0, 45, 109, 0.18);
}

.campaigns .overlay:after {
  content: "";
  display: table;
  clear: both;
}

.hiring {
  display: none;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 50px 0;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(-15px);
  transition: opacity .4s, transform .4s;
}

.hiring .close {
  float: right;
  margin-right: -38px;
  margin-top: -20px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  font-size: 14px;
  color: #aaa;
}

.hiring .close:hover {
  color: #F25028;
  cursor: pointer;
}

.hiring .close:active {
  margin-top: -19px;
}

.hiring.is-shown {
  opacity: 1;
  transform: translateY(0px);
}

.hiring__container {
  background-color: #FFF;
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 50px 30px 50px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.hiring__container .interested {
  border-top: 1px solid #ececec;
  padding-top: 15px;
  padding-bottom: 10px;
}

.hiring__container .interested .button {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-left: 20px;
}

.hiring__container a {
  color: #F25028;
}

.hiring__container p, .hiring__container h4, .hiring__container h3, .hiring__container h2, .hiring__container ul {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.hiring__container h3 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 38px;
  line-height: 50px;
  margin: 5px 0px 20px 0px;
  color: #333;
  border-bottom: 1px solid #ececec;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.hiring__container h3 img {
  height: 32px;
  width: 32px;
  vertical-align: middle;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  margin-top: -5px;
}

.hiring__container h4 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 50px;
  margin: 10px 10px 0px 10px;
  color: #333;
}

.hiring__container p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  line-height: 28px;
  padding: 10px 0;
  font-size: 15px;
  color: #202020;
}

.hiring__container ul {
  margin-left: 25px;
  padding-bottom: 30px;
}

.hiring__container li {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  line-height: 28px;
  font-size: 15px;
  color: #202020;
}

.discover {
  background-color: #EAEAEA;
  padding-left: 30px;
  padding-top: 80px;
  padding-bottom: 100px;
}

.discover:after {
  content: "";
  display: table;
  clear: both;
}

.discover .u-constrict {
  max-width: 1540px;
}

.discover .screen {
  max-width: 891px;
  height: auto;
  width: 100%;
  float: left;
  margin-right: 70px;
}

.discover .p {
  color: #444;
  max-width: 700px;
}

.discover h2 {
  color: #444;
  border: none;
  font-size: 46px;
}

.discover .stats {
  border-left: 1px solid #EF512C;
  padding-left: 20px;
  margin-top: 75px;
  float: left;
  margin-right: -60px;
}

.discover .stats:before {
  content: '';
  display: block;
  position: absolute;
  height: 100px;
  width: 116px;
  margin-left: -136px;
  margin-top: 16px;
  border-bottom: 1px solid #EF512C;
}

.discover .stats h4 {
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  color: #757575;
  padding-bottom: 4px;
}

.discover .stats td {
  font-size: 18px;
  width: 250px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.demo {
  background-image: url(/images/mosaic.jpg);
  animation: animatedBackgroundOpposite 70s linear infinite;
  background-color: #000;
}

.demo .u-constrict {
  max-width: 1100px;
}

.demo .overlay {
  padding: 60px 0;
  background-color: rgba(0, 45, 109, 0.18);
}

.demo .overlay:after {
  content: "";
  display: table;
  clear: both;
}

.demo .p {
  font-size: 16px;
}

.demo .button {
  float: left;
  margin-top: 0px;
  margin-right: 40px;
  width: 200px;
}

.footer {
  background-color: #010509;
  padding-top: 100px;
  padding-bottom: 10px;
  color: #777;
  font-weight: 300;
  font-size: 13px;
  text-align: right;
}

.form {
  background-color: #fff;
  text-align: center;
  padding: 25px 0;
  max-width: 520px;
  margin: 0 auto;
  border-radius: 4px;
  padding-bottom: 0;
  overflow: hidden;
  margin-top: 70px;
  margin-bottom: 70px;
  display: none;
}

.form:after {
  content: "";
  display: table;
  clear: both;
}

.form .success {
  display: none;
}

.form.is-success .success {
  display: block;
  padding-bottom: 25px;
}

.form.is-success .success .p {
  color: #444;
  padding-left: 30px;
  padding-right: 30px;
}

.form.is-success .hideOnSuccess {
  display: none;
}

.form .button {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  padding-top: 27px;
  padding-bottom: 27px;
  margin-top: 30px;
  outline: none;
}

.form .button .spinner {
  display: none;
}

.form .validate {
  background-color: #FEF4F2;
  border: 1px solid #FEECE8;
  color: #D2310A;
  padding: 20px 10px;
  margin-bottom: -30px;
  display: none;
}

.form input {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #eaeaea;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 300;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
}

.form input:focus {
  border-color: #F25028;
}

.form .h2 {
  margin-bottom: 0;
  padding-bottom: 0;
  color: #555;
  padding-bottom: 25px;
  border-bottom: 1px solid #ddd;
  font-size: 40px;
}

.form.is-loading .button {
  background-color: #ddd;
  cursor: default;
  color: #aaa;
}

.form.is-loading .button .spinner {
  display: inline-block;
  margin-top: -4px;
  margin-left: -45px;
  position: absolute;
}

.form.is-loading .button:hover {
  text-shadow: none;
}

@media (max-width: 1520px) {
  .discover {
    text-align: center;
  }
  .discover .p {
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .discover .screen {
    float: none;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .discover .stats {
    width: auto;
    float: none;
    margin: 0 auto;
    border-left: none;
    border-top: 1px solid #EF512C;
    padding-left: 0;
    width: 430px;
    padding-left: 20px;
  }
  .discover .stats td {
    text-align: left;
    width: 250px;
  }
  .discover .stats td:last-child {
    width: 180px;
  }
  .discover .stats:before {
    content: '';
    display: block;
    position: absolute;
    height: 377px;
    width: 116px;
    margin-left: 250px;
    margin-top: -377px;
    border-right: 1px solid #EF512C;
    border-bottom: none;
  }
}

@media (max-width: 1400px) {
  .campaigns .p {
    padding-right: 30px;
  }
  .campaigns img {
    margin-right: 50px;
  }
}

@media (max-width: 1215px) {
  .numbers {
    padding-left: 50px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .numbers img {
    margin-left: 40px;
  }
  .numbers .h2 {
    padding-top: 0;
  }
  .campaigns .h2 {
    padding-top: 0;
  }
  .campaigns img {
    width: 50%;
  }
}

@media (max-width: 1100px) {
  .campaigns {
    text-align: center;
  }
  .campaigns .overlay {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .campaigns .h2 {
    padding-bottom: 0;
  }
  .campaigns img {
    float: none;
    border-bottom: 1px solid #aaa;
    margin-bottom: 20px;
    margin-right: 0;
    width: 100%;
    max-width: 550px;
  }
  .campaigns .p {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1050px) {
  .hero {
    height: auto;
  }
  .hero .whiteout {
    display: none;
  }
  .hero .overlay {
    padding-bottom: 0px;
  }
  .hero .container {
    margin: 0 !important;
    width: auto !important;
    background-size: 500px auto;
    background-position: top center;
  }
  .hero .container .copy {
    opacity: 0;
    margin: 0;
    text-align: center;
  }
  .hero .container .copy .padding {
    padding: 0;
    padding-top: 330px;
  }
  .hero .container .copy p {
    margin: 0 auto;
  }
  body.is-loaded .hero .container .copy {
    opacity: 1;
  }
  .brands {
    padding: 10px 0;
    margin: 0;
    line-height: auto;
    line-height: 16px;
  }
  .brands img {
    display: inline-block;
    width: 40%;
    margin: 0;
    max-width: 150px;
    height: auto;
  }
  .demo {
    padding: 0;
    text-align: center;
  }
  .demo .overlay {
    padding-bottom: 20px;
    padding-top: 35px;
  }
  .demo .button {
    float: none;
    margin: 0;
  }
  .demo .p {
    padding-top: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 987px) {
  .numbers {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
  }
  .numbers img {
    margin: 0;
    float: none;
    width: 100%;
    max-width: 600px;
    border-bottom: 1px solid #eaeaea;
  }
  .numbers .h2 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .numbers .p {
    margin: 0 auto;
  }
  .discover {
    padding: 50px 30px;
  }
  .discover .stats:before {
    display: none;
  }
}

@media (max-width: 950px) {
  .hiring__container {
    margin-right: 30px;
    margin-left: 30px;
  }
}

@media (max-width: 700px) {
  .hiring__container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .hiring__container .close {
    margin-right: -20px;
  }
  .hiring h3 {
    line-height: 40px;
    font-size: 30px;
  }
  .hiring h3 span {
    display: block;
    padding-bottom: 10px;
  }
  .hiring h4 {
    line-height: 30px;
    font-size: 24px;
    padding-left: 0;
    margin-left: 0;
  }
  .hiring ul {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 625px) {
  .header {
    text-align: center;
  }
  .header .home-link {
    float: none;
  }
  .nav .button {
    display: none;
  }
  .hero {
    min-height: 0px;
    max-width: none;
    height: auto !important;
  }
  .hero .whiteout {
    display: none;
  }
  .hero .overlay {
    padding-bottom: 50px;
    height: auto !important;
  }
  .hero .container {
    background-size: 110% auto;
    height: auto !important;
    padding-bottom: 60px;
  }
  .hero .container .copy {
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .hero .container .copy .button {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    display: block;
  }
  .hero .container .copy .padding {
    padding-top: 80%;
  }
  .hero .container .copy h2 {
    font-size: 32px;
    padding-bottom: 15px;
    line-height: 40px;
  }
  .hero .container .copy h2 .tm {
    font-weight: 300;
    font-size: 14px;
    margin-top: -16px;
    opacity: 0.7;
  }
  .hero .container .copy p {
    line-height: 26px;
    text-align: center;
    font-size: 14px;
  }
  .numbers {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;
  }
  .numbers .h2 {
    font-size: 32px;
    padding-bottom: 15px;
    padding-top: 25px;
    line-height: 40px;
  }
  .numbers .p {
    padding-left: 15px;
    padding-right: 15px;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
  }
  .campaigns .overlay {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .campaigns .h2 {
    font-size: 32px;
    padding-bottom: 15px;
    padding-top: 25px;
    line-height: 40px;
  }
  .campaigns .p {
    padding-left: 15px;
    padding-right: 15px;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
  }
  .discover {
    padding: 30px 0;
  }
  .discover .h2 {
    font-size: 32px;
    padding-bottom: 15px;
    padding-top: 25px;
    line-height: 40px;
  }
  .discover .screen {
    margin-bottom: 15px;
  }
  .discover .p {
    padding-left: 15px;
    padding-right: 15px;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
  }
  .discover .stats {
    width: auto;
    padding-top: 10px;
  }
  .discover .stats table {
    display: block;
    width: auto;
  }
  .discover .stats tr {
    display: block;
    width: auto;
  }
  .discover .stats tbody {
    display: block;
    width: auo;
  }
  .discover .stats td {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    width: auto !important;
    text-align: left;
    border-bottom: 1px solid #dadada;
    font-size: 15px;
  }
  .discover .stats td h4 {
    font-size: 11px;
  }
  .demo .p {
    font-size: 14px;
    line-height: 24px;
  }
  .form {
    margin-top: 25px;
    margin-bottom: 35px;
    padding-top: 15px;
  }
  .form input {
    font-size: 17px;
  }
  .form .h2 {
    font-size: 24px;
    padding-bottom: 15px;
  }
}

.u-nowrap {
  white-space: nowrap;
}

.u-fr {
  float: right;
}

.u-fl {
  float: left;
}

.u-constrict {
  max-width: 1920px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
